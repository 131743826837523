import {useLocation, useNavigate} from "react-router-dom";
import {AppBar, Box, Divider, Toolbar, Typography} from "@mui/material";
import Stack from '@mui/material/Stack';
import {useMsal} from "@azure/msal-react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ButtonBase } from './buttonBase.component';
import {DropDownItem, DropdownMenuComponent} from "./dropdownMenu.component";
import {useScrollYPosition} from "../misc.hooks";

const assetDataDropdownItems: DropDownItem[] = [
    {
        text: "Engine Asset Data",
        path: "/engine_data",
        requiredRoles: ["otam_admin", "otam_engine_data", "otam_engine_data_read"]
    },
    {
        text: "Asset Scan Data",
        path: "/asset_scan_data",
        requiredRoles: ["otam_admin", "otam_asset_scan_data", "otam_asset_scan_data_read"]
    },
    {
        text: "SES Asset Data",
        path: "/ses_data",
        requiredRoles: ["otam_admin", "otam_ses_data", "otam_ses_data_read"]
    },
    {
        text: "ES&O Asset Data",
        path: "/eso_data",
        requiredRoles: ["otam_admin", "otam_eso_data", "otam_eso_data_read"]
    },
    {
        text: "Plantnet Asset Data",
        path: "/plantnet",
        requiredRoles: ["otam_admin", "otam_plantnet_data", "otam_plantnet_data_read"]
    },
    {
        text: "SCR Asset Data",
        path: "/scr_data",
        requiredRoles: ["otam_admin", "otam_scr_data", "otam_scr_data_read"]
    },
    {
        text: "Marine sWOIS Asset Data",
        path: "/swois_data",
        requiredRoles: ["otam_admin", "otam_swois_data", "otam_swois_data_read"]
    },
    {
        text: "WDCU Asset Data",
        path: "/wdcu_data",
        requiredRoles: ["otam_admin", "otam_wdcu_data", "otam_wdcu_data_read"]
    },
    {
        text: "PCS Asset Data",
        path: "/pcs_data",
        requiredRoles: ["otam_admin", "otam_pcs_data", "otam_pcs_data_read"]
    },
];

const internalDropDownItems: DropDownItem[] = [
    {
        text: "Internal Sites",
        path: "/internal_sites",
        requiredRoles: ["otam_admin", "otam_internal", "otam_internal_read"]
    },
    {
        text: "All Site Assets",
        path: "/internal_assets",
        requiredRoles: ["otam_admin", "otam_internal", "otam_internal_read"]
    }
];

export const allRoles = [
    "otam_admin",
    "otam_user",
    "otam_engine_data",
    "otam_engine_data_read",
    "otam_asset_scan_data",
    "otam_asset_scan_data_read",
    "otam_ses_data",
    "otam_ses_data_read",
    "otam_eso_data",
    "otam_eso_data_read",
    "otam_plantnet_data",
    "otam_plantnet_data_read",
    "otam_scr_data",
    "otam_scr_data_read",
    "otam_installations_and_equipment",
    "otam_psirt_admin",
    "otam_internal",
    "otam_internal_read",
    "otam_patch_validation",
    "otam_swois_data",
    "otam_swois_data_read",
    "otam_wdcu_data",
    "otam_wdcu_data_read",
    "otam_pcs_data",
    "otam_pcs_data_read",
    "otam_upload",
]

export function Navigation(): JSX.Element {
    const {instance} = useMsal();
    const [account] = instance.getAllAccounts();
    const navigate = useNavigate();
    const location = useLocation();
    const scrollY = useScrollYPosition();

    return <Box sx={{ width: "100%", zIndex: 2, position: "relative"}}>
        <AppBar className="appbar" position="relative" color="transparent">
            <Toolbar className="toolbar" variant="dense">
                <Box className="logoBackground">
                    <Box
                        className="appbarImgParent"
                        sx={{cursor: "pointer"}}
                        onClick={() => {
                        navigate("");
                    }}>
                        <Stack
                            className="stack"
                            spacing={1}
                            padding={2}
                            direction="row"
                            background-color="#0f334a"
                            style={{margin: 0}}
                            divider={<Divider orientation="vertical" flexItem />}
                        >
                            <img
                                className="appbarImg"
                                id="logo-img"
                                src="/img/wartsila.png"
                                alt="Wärtsilä Logo"
                            />
                            <img
                                className="appbarImg"
                                id="logo-img"
                                src="/img/OTAM@4x-8.png"
                                alt="OTAM Logo"
                            />
                        </Stack>
                    </Box>
                </Box>
                <Box style={{ float: "left", marginRight: "auto" }}>
                    <Stack
                        className="stack"
                        spacing={0}
                        padding={1}
                        direction="row"
                        background-color="#0f334a"
                    >
                        <ButtonBase text="Home" path="" requiredRoles={[]}/>
                        <ButtonBase text="Installations" path="/installations" requiredRoles={allRoles}/>
                        <DropdownMenuComponent name="Internal Data" items={internalDropDownItems} posX={0} posY={47} path={location.pathname}/>
                        <DropdownMenuComponent name="Asset Data" items={assetDataDropdownItems} posX={-29} posY={47} path={location.pathname}/>
                        <ButtonBase text="Registered Products" path="/asset_groups" requiredRoles={allRoles}/>
                        <ButtonBase text="Patch Validation" path="/validation" requiredRoles={["otam_admin", "otam_patch_validation"]}/>
                        <ButtonBase text="Upload" path="/upload" requiredRoles={["otam_admin", "otam_upload"]}/>
                    </Stack>
                </Box>
                <Box className="avatarHolder">
                    <Typography style={{
                        paddingTop: 5,
                        paddingRight: "8px",
                        margin: 0,
                        fontFamily: "Noto Sans",
                        fontSize: 16,
                        color: "#ffffff",
                        float: "left"
                    }}>
                        {account.name}
                    </Typography>
                    <img src="/img/user_m.svg" alt="user avatar" width="30px" height="30px" style={{ float:"right" }}/>
                </Box>
            </Toolbar>
        </AppBar>
        <Box className="backToTopParent">
            <Box className="backToTop" sx={{
                right: scrollY >= 60 ? "20px": "-80px",
                transition: "right 0.4s",
            }}
            onClick={() => {
                window.scrollTo({top: 0, behavior: "smooth"});
            }}>
                <KeyboardArrowUpIcon sx={{
                    paddingLeft: "7px",
                    paddingTop: "6px",
                    height: "75%",
                    width: "75%",
                    color: "#ffffff",
                }}/>
            </Box>
        </Box>
    </Box>
}
