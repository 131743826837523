import React, {FormEvent, useEffect, useState} from "react";
import {Alert, Box, Collapse, Container, Divider, List, ListItem, Typography} from "@mui/material";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {NavLink} from "react-router-dom";
import {IsFieldArray, AssetScanInfo} from "../assetScan.types";
import {GetString} from "../../../localization";
import {useAuth} from "../../auth/auth.hooks";
import {useAssetScanItem} from "../assetScan.hooks";
import {Banner} from "../../banner/banner.component";
import {CancelButton, EditButton, InfoFieldPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
import {useInstallationDetails} from "../../installation/installation.hooks";
import {InstallationInfo} from "../../installation/installation.types";
import {useHistory} from "../../history/history.hooks";
import {ParseRevisionData} from "../../history/history.misc";
import {FieldHistorySet} from "../../history/history.types";

export const withHooksHOC = (Component: React.ElementType) => function useHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component accessToken={accessToken} config={config} queryClient={queryClient} />;
};

interface ShowAssetScanProps {
    assetId: string;
    instId: string;
}

interface InfoBoxProps {
    name: string;
    data: AssetScanInfo;
    content: string[];
    historyChanges: FieldHistorySet[];
}

interface InstInfoBoxProps {
    name: string;
    data: InstallationInfo;
    content: string[];
}

interface InstallationListBoxProps {
    name: string;
    data: string[];
    content: string[];
}

interface ViewState {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
}

const assetScanInfoContents: string[] = [
    'name',
    'type',
    'vendor',
    'model',
    'ip_addresses',
    'firmware_version',
    'serial_number',
    'product_version',
    'hardware_order_number',
    'memory_card_serial',
    'operating_system',
]

const installationContents: string[] = [
    'name',
    'version',
    'installDate',
]

const patchingContents: string[] = [
    'description',
    'hotFixId',
    'installedOn',
]

class ViewAssetScan extends React.Component<IHooksHOCProps, ViewState> {
    static id = "";

    static installation = "";

    static IsWinAsset = (str: string): boolean => {
        if (str === undefined || str === null) {
            return false;
        }
        return str.toLowerCase().includes("windows");
    }

    static InstallationListBox = ({name, data, content}: InstallationListBoxProps): JSX.Element => {
        const [collapse, setCollapse] = useState(true);

        const installations = Object(data);

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: "pointer"}}>
                <Box sx={{display: "flex"}} onClick={() => {
                    setCollapse(!collapse);
                }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={{pt: "3px"}}/> : <KeyboardArrowUpIcon sx={{pt: "3px"}}/>}
                    </Box>
                </Box>
                <Collapse in={!collapse} sx={{cursor: "auto"}}>
                    <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                    <Box>
                        <List>
                            {installations === null || Object.keys(installations).length === 0 ?
                                <br/> : installations.map((item: { [x: string]: string }) => (
                                    <ListItem key={ item[content[0]] + item[content[1]] + item[content[2]]}>
                                        <Typography>
                                            <strong>{item[content[0]]}</strong>
                                            <br/>
                                            {item[content[1]]}
                                            <br/>
                                            {item[content[2]]}
                                            <br/>
                                        </Typography>
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                </Collapse>
            </Box>
        );
    }

    static FormatFieldData(data: number | string | string[]): string {
        if(Array.isArray(data)) {
            const list = data.toString();
            return list.split(",").join("\n");
        }

        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
        }

        ViewAssetScan.IsWinAsset = ViewAssetScan.IsWinAsset.bind(this);
        ViewAssetScan.InstallationListBox = ViewAssetScan.InstallationListBox.bind(this);
        this.InfoBox = this.InfoBox.bind(this);
        this.ShowAsset = this.ShowAsset.bind(this);
        this.EditFormSubmit = this.EditFormSubmit.bind(this);
        this.PostUpdatedData = this.PostUpdatedData.bind(this);
    }

    InfoBox = ({name, data, content, historyChanges}: InfoBoxProps): JSX.Element => {
        const [collapse, setCollapse] = useState(false);

        const {FormatFieldData} = ViewAssetScan;
        const {updating} = this.state;
        const {editing} = this.state;

        const assetName = FormatFieldData(data[content[0]])

        useEffect(() => {
            if(editing) {
                setCollapse(false);
            }
        },[editing])

        const collapseArrowStyle = {pt: "3px", color: editing ? "#c0c0c0" : "#000000"}

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: editing ? "default" : "pointer"}}>
                <Box sx={{display: "flex"}} onClick={() => {
                    if(!editing) {
                        setCollapse(!collapse);
                    }
                }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={collapseArrowStyle}/> : <KeyboardArrowUpIcon sx={collapseArrowStyle}/>}
                    </Box>
                </Box>
                <Collapse in={!collapse} sx={{cursor: "auto"}}>
                    <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                    <Box style={{display: "flex"}}>
                        <Box style={{float: "left", width: "30%"}}>
                            <InfoFieldPair title={GetString(content[0])}
                                           name={content[0]}
                                           data={FormatFieldData(data[content[0]])}
                                           multiline={Array.isArray(data[content[0]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[0])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[1])}
                                           name={content[1]}
                                           data={FormatFieldData(data[content[1]])}
                                           multiline={Array.isArray(data[content[1]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[1])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[2])}
                                           name={content[2]}
                                           data={FormatFieldData(data[content[2]])}
                                           multiline={Array.isArray(data[content[2]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[2])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[3])}
                                           name={content[3]}
                                           data={FormatFieldData(data[content[3]])}
                                           multiline={Array.isArray(data[content[3]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[3])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                        </Box>
                        <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                            <InfoFieldPair title={GetString(content[4])}
                                           name={content[4]}
                                           data={FormatFieldData(data[content[4]])}
                                           multiline={Array.isArray(data[content[4]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[4])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[5])}
                                           name={content[5]}
                                           data={FormatFieldData(data[content[5]])}
                                           multiline={Array.isArray(data[content[5]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[5])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[6])}
                                           name={content[6]}
                                           data={FormatFieldData(data[content[6]])}
                                           multiline={Array.isArray(data[content[6]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[6])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[7])}
                                           name={content[7]}
                                           data={FormatFieldData(data[content[7]])}
                                           multiline={Array.isArray(data[content[7]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[7])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                        </Box>
                        <Box style={{float: "right", width: "30%", flexGrow: 2}} sx={{ml: 3}}>
                            <InfoFieldPair title={GetString(content[8])}
                                           name={content[8]}
                                           data={FormatFieldData(data[content[8]])}
                                           multiline={Array.isArray(data[content[8]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[8])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            <InfoFieldPair title={GetString(content[9])}
                                           name={content[9]}
                                           data={FormatFieldData(data[content[9]])}
                                           multiline={Array.isArray(data[content[9]])}
                                           updating={updating}
                                           editing={editing}
                                           historyData={historyChanges.find(x => x.name === content[9])}
                                           objectId={ViewAssetScan.id}
                                           source="asset_scan_data"
                                           assetName={assetName}
                            />
                            { ViewAssetScan.IsWinAsset(data.operating_system) ?
                                <InfoFieldPair title={GetString(content[10])}
                                               name={content[10]}
                                               data={FormatFieldData(data[content[10]])}
                                               multiline={Array.isArray(data[content[10]])}
                                               updating={updating}
                                               editing={editing}
                                               historyData={historyChanges.find(x => x.name === content[10])}
                                               objectId={ViewAssetScan.id}
                                               source="asset_scan_data"
                                               assetName={assetName}
                                /> : "" }
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        );
    }

    InfoBoxInst = ({name, data, content}: InstInfoBoxProps): JSX.Element => {
        const [collapse, setCollapse] = useState(true);

        const {FormatFieldData} = ViewAssetScan;
        const {updating} = this.state;
        const {editing} = this.state;

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: "pointer"}}>
                <Box sx={{display: "flex"}} onClick={() => {
                    setCollapse(!collapse);
                }}>
                    <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                        {name}
                    </Typography>
                    <Box sx={{ml: "10px"}}>
                        {collapse ? <KeyboardArrowDownIcon sx={{pt: "3px"}}/> : <KeyboardArrowUpIcon sx={{pt: "3px"}}/>}
                    </Box>
                </Box>
                <Collapse in={!collapse} sx={{cursor: "auto"}}>
                    <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                    <Box style={{display: "flex"}}>
                        <Box style={{float: "left", width: "50%"}}>
                            <InfoFieldPair title={GetString(content[0])} name={content[0]} data={FormatFieldData(data[content[0]])} multiline={Array.isArray(data[content[0]])} updating={updating} editing={editing} editable={false}/>
                            <InfoFieldPair title={GetString(content[1])} name={content[1]} data={FormatFieldData(data[content[1]])} multiline={Array.isArray(data[content[1]])} updating={updating} editing={editing} editable={false}/>
                            <InfoFieldPair title={GetString(content[2])} name={content[2]} data={FormatFieldData(data[content[2]])} multiline={Array.isArray(data[content[2]])} updating={updating} editing={editing} editable={false}/>
                            <Box>
                                <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
                                    <ul className="homePageList" style={{ margin: 0 }}>
                                        <li>
                                            <NavLink to={`/installations/${data.installation_id}`} className="genericLink">
                                                View Installation
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Typography>
                                <br/>
                            </Box>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        );
    }

    PostUpdatedData = (data: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;

        const conf = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/upload/asset_scan_data/update`, data, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['asset_scan_item', { 'id': ViewAssetScan.id }], { exact: true })
                    queryClient.invalidateQueries(['history', { 'source': "asset_scan_data", 'id': ViewAssetScan.id}])
                }

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "success"
                })
            }).catch(() => {
            this.setState({
                editing: false,
                updating: false,
                updateStatus: "failed"
            })
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true
            });
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);

            data.append("object_id", ViewAssetScan.id);

            const object = {} as AssetScanInfo;
            data.forEach((value, key)=> {
                if(IsFieldArray(key)) {
                    object[key] = value.toString().split("\n");
                }
                else {
                    object[key] = value as string;
                }
            });

            const json = JSON.stringify(object);
            this.PostUpdatedData(json)
        }
    }

    ShowAsset = ({assetId, instId}: ShowAssetScanProps): JSX.Element => {
        if(assetId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>Asset ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {InfoBoxInst} = this;
        const {InstallationListBox} = ViewAssetScan;
        const {dataLoaded} = this.state;

        const assetData = useAssetScanItem(assetId);
        const instData = useInstallationDetails(instId);
        const revisionData = useHistory("asset_scan_data", assetId)

        const revisionChanges = ParseRevisionData(revisionData.data);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true
            });
        }

        return (
            <Box>
                {(assetData.isLoading || instData.isLoading) && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {(assetData.isError || instData.isError) && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {assetData.isSuccess && instData.isSuccess && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={assetData.data[0]} content={assetScanInfoContents}
                                historyChanges={revisionChanges}
                            />
                        </Box>
                        <Box className="viewAssetContainer">
                            <InfoBoxInst
                                name="Installation"
                                data={instData.data} content={['installation_id', 'name', 'type']}
                            />
                        </Box>
                        <Box className="viewAssetContainer">
                            { ViewAssetScan.IsWinAsset(assetData.data[0].operating_system) ?
                                <InstallationListBox
                                    name={GetString("installed_software")}
                                    data={assetData.data[0].installed_software}
                                    content={installationContents}
                                /> : "" }
                        </Box>
                        <Box className="viewAssetContainer">
                            { ViewAssetScan.IsWinAsset(assetData.data[0].operating_system) ?
                                <InstallationListBox
                                    name={GetString("patching_level")}
                                    data={assetData.data[0].patching_level}
                                    content={patchingContents}
                                /> : "" }
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowAsset} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {dataLoaded} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const params = parts[2].split("+");

        // eslint-disable-next-line prefer-destructuring
        ViewAssetScan.id = params[0];
        // eslint-disable-next-line prefer-destructuring
        ViewAssetScan.installation = params[1];

        return (
            <Box>
                <Banner firstLine="Asset Scan Data" secondLine={`View And Edit Asset ID ${ViewAssetScan.id}`}/>
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            <CancelButton
                                editing={editing}
                                updating={updating}
                                onClick={() => {
                                    this.setState({
                                        editing: false
                                    })
                                }}
                            />
                            <EditButton requiredRoles={["otam_admin", "otam_asset_scan_data"]} editing={editing} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowAsset assetId={ViewAssetScan.id} instId={ViewAssetScan.installation}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewAssetScan);
