import {Box, Link} from "@mui/material";
import { useAuth } from '../auth/auth.hooks';


export function Footer(): JSX.Element {
    const { config } = useAuth();
    const versionString = `${config.REACT_APP_VERSION}`
    let version = `Version: ${config.REACT_APP_VERSION}`;
    // REACT_APP_VERSION gets generated in format of v1.2.3-x-gabc123 when git tag is found
    // Where v1.2.3 part comes from git tag and abc123 from commit hash
    const versionData = versionString.split('-');
    if (versionData.length === 3) {
        // Trim first letter in git hash (versionData[2]) as generation ads an extra letter at the beginning
        version = `Version: ${versionData[0]} (${versionData[2].substring(1)})`;
    }
    
    return <Box className="footer" sx={{ width: "100%",}}>
        <Box paddingLeft={3} paddingBottom={3} paddingTop={1}>
                <Link className="genericLink" target="_blank" href="https://confluence.devops.wartsila.com/pages/viewrecentblogposts.action?key=OC" rel="noreferrer">
                {version}
                </Link>
        </Box>
    </Box>
}
