import React, {FormEvent} from "react";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
import axios from "axios";
import {useAuth} from "../../auth/auth.hooks";
import {WdcuInfo} from "../wdcu.types";
import {CancelButton, EditButton, InfoFieldPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
import {GetString} from "../../../localization";
import {Banner} from "../../banner/banner.component";
import {useWdcuItem} from "../wdcu.hooks";
import {AssetScanInfo, IsFieldArray} from "../../assetScan/assetScan.types";
import { validateMacAddress } from "../../misc";
import {useHistory} from "../../history/history.hooks";
import {ParseRevisionData} from "../../history/history.misc";
import {FieldHistorySet} from "../../history/history.types";


export const withHooksHOC = (Component: React.ElementType) => function useHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component accessToken={accessToken} config={config} queryClient={queryClient}/>;
}

interface ShowWdcuProps {
    objectId: string;
}

interface InfoBoxProps {
    name: string;
    data: WdcuInfo;
    content: string[];
    historyChanges: FieldHistorySet[];
}

interface ViewWdcuProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
}

const wdcuInfoContents: string[] = [
    'name',
    'installation_id',
    'serial',
    'type',
    'model',
    'vendor',
    'description',
    'ip_address',
    'mac_addresses',
    'firmware_version',
    'created_date',
]

class ViewWdcu extends React.Component<any, ViewWdcuProps> {
    static serial = "";

    static objectId = "";

    static FormatFieldData(data: number | string | string[]): string {
        if(Array.isArray(data)) {
            const list = data.toString();
            return list.split(",").join("\n");
        }

        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
        }
    }

    InfoBox = ({name, data, content, historyChanges}: InfoBoxProps): JSX.Element => {
        const {FormatFieldData} = ViewWdcu;
        const {updating} = this.state;
        const {editing} = this.state;

        const assetName = FormatFieldData(data[content[0]]);

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px"}}>
                <Typography style={{color: "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoFieldPair title={GetString(content[0])}
                                       name={content[0]}
                                       data={FormatFieldData(data[content[0]])}
                                       multiline={Array.isArray(data[content[0]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[0])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[1])}
                                       name={content[1]}
                                       data={FormatFieldData(data[content[1]])}
                                       multiline={Array.isArray(data[content[1]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[1])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[2])}
                                       name={content[2]}
                                       data={FormatFieldData(data[content[2]])}
                                       multiline={Array.isArray(data[content[2]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[2])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[3])}
                                       name={content[3]}
                                       data={FormatFieldData(data[content[3]])}
                                       multiline={Array.isArray(data[content[3]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[3])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[4])}
                                       name={content[4]}
                                       data={FormatFieldData(data[content[4]])}
                                       multiline={Array.isArray(data[content[4]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[4])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[5])}
                                       name={content[5]}
                                       data={FormatFieldData(data[content[5]])}
                                       multiline={Array.isArray(data[content[5]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[5])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[6])}
                                       name={content[6]}
                                       data={FormatFieldData(data[content[6]])}
                                       multiline={Array.isArray(data[content[6]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[6])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[7])}
                                       name={content[7]}
                                       data={FormatFieldData(data[content[7]])}
                                       multiline={Array.isArray(data[content[7]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[7])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                    </Box>
                    <Box style={{float: "right", width: "30%", flexGrow: 2}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[8])}
                                       name={content[8]}
                                       data={FormatFieldData(data[content[8]])}
                                       multiline={Array.isArray(data[content[8]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[8])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[9])}
                                       name={content[9]}
                                       data={FormatFieldData(data[content[9]])}
                                       multiline={Array.isArray(data[content[9]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[9])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[10])}
                                       name={content[10]}
                                       data={FormatFieldData(data[content[10]])}
                                       multiline={Array.isArray(data[content[10]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[10])}
                                       objectId={ViewWdcu.objectId}
                                       source="wdcu_info"
                                       assetName={assetName}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    PostUpdatedData = (data: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;

        const conf = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/upload/wdcu_info/update`, data, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['wdcu_info_item', { 'id': ViewWdcu.objectId }], { exact: true })
                    queryClient.invalidateQueries(['history', { 'source': "wdcu_info", 'id': ViewWdcu.objectId}])
                }

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "success"
                })
            }).catch(() => {
            this.setState({
                editing: false,
                updating: false,
                updateStatus: "failed"
            })
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true,
            })
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);

            data.append("object_id", ViewWdcu.objectId);

            const object = {} as AssetScanInfo;
            data.forEach((value, key)=> {
                if(IsFieldArray(key)) {
                    object[key] = value.toString().split("\n");
                }
                else {
                    object[key] = value as string;
                }
            });

            if(object.mac_addresses !== undefined) {
                for(let i = 0; i < object.mac_addresses.length; i += 1) {
                    const valid = validateMacAddress(object.mac_addresses[i]);

                    if(!valid) {
                        this.setState({
                            updating: false,
                            updateStatus: "macError",
                        })

                        return;
                    }
                }
            }

            const json = JSON.stringify(object);
            this.PostUpdatedData(json)
        }
    }

    ShowWdcu = ({objectId}: ShowWdcuProps): JSX.Element => {
        if(objectId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {dataLoaded} = this.state;

        const assetData = useWdcuItem(objectId);
        const revisionData = useHistory("wdcu_info", objectId)

        const revisionChanges = ParseRevisionData(revisionData.data);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true
            });
        }

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {assetData.isSuccess && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={assetData.data[0]} content={wdcuInfoContents}
                                historyChanges={revisionChanges}
                            />
                            {/* <AssetInfoBox
                            name="Installation Details"
                            data={assetData.data[0]} content={assetInfoContents}
                        /> */}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowWdcu} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {dataLoaded} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const params = parts[2].split("+");

        // eslint-disable-next-line prefer-destructuring
        ViewWdcu.serial = decodeURIComponent(params[1]);
        // eslint-disable-next-line prefer-destructuring
        ViewWdcu.objectId = params[0];

        return (
            <Box>
                <Banner firstLine="WDCU Asset Data" secondLine={`View Asset ${ViewWdcu.serial}`}/>
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            <CancelButton
                                editing={editing}
                                updating={updating}
                                onClick={() => {
                                    this.setState({
                                        editing: false
                                    })
                                }}
                            />
                            <EditButton requiredRoles={["otam_admin", "otam_wdcu_data"]} editing={editing} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowWdcu objectId={ViewWdcu.objectId}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewWdcu);
