import React, {useEffect, useRef, useState} from "react";
import {Alert, Container, Box, SelectChangeEvent, Button} from "@mui/material";
import {GRID_CHECKBOX_SELECTION_COL_DEF, GridColumnVisibilityModel, GridFilterModel, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {useSwoisInfoCount, useSwoisInfo} from './swois.hooks';
import {Banner} from "../banner/banner.component";
import {CustomDataGrid} from "../dataGrid/dataGrid.component";
import {columnSearchCacheGet, columnSearchCacheSet, searchCacheGet, searchCacheSet} from "../dataGrid/searchCache";

interface gridProps {
    id: string | undefined;
    searchCacheKey: string;
    useColumnFilters?: boolean;
    blendToTabs?: boolean;
}

const initialVisibilityModel = {
    object_id: false,
    type: false,
    firmware_version: false,
    operating_system: false,
};

export function SwoisDataGrid({id, searchCacheKey, useColumnFilters, blendToTabs}: Readonly<gridProps>): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [visibilityModel, setVisibilityModel] = useState<any>(initialVisibilityModel);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(undefined);
    const [columnSearch, setColumnSearch] = useState(columnSearchCacheGet(searchCacheKey));
    const [columnSearchParams, setColumnSearchParams] = useState(columnSearchCacheGet(searchCacheKey));

    const filter = useRef<any[] | undefined>(undefined);
    const rowCount = useSwoisInfoCount(search, id, columnSearchParams);
    const SwoisInfo = useSwoisInfo(paginationModel.pageSize, paginationModel.page, search, id, sortModel, columnSearchParams);
    const navigate = useNavigate();

    const columnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false},
        {field: "name", headerName: "Name", minWidth: 150, flex: 1, editable: false},
        {field: "type", headerName: "Type", minWidth: 140, flex: 1, editable: false},
        {field: "vendor", headerName: "Vendor", minWidth: 150, flex: 1, editable: false},
        {field: "model", headerName: "Model", minWidth: 170, flex: 1, editable: false},
        {field: "serial", headerName: "Serial", minWidth: 170, flex: 1, editable: false},
        {field: "description", headerName: "Description", minWidth: 170, flex: 1, editable: false},
        {field: "firmware_version", headerName: "Firmware Version", minWidth: 170, flex: 1, editable: false},
        {field: "installation_id", headerName: "Installation ID", minWidth: 170, flex: 1, editable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.object_id;
                    // eslint-disable-next-line prefer-destructuring
                    const serial = params.row.serial;
                    navigate({pathname: `/swois_data/${objectId}+${serial}`})
                }

                return(
                    <Button
                        style={{
                            float: "right"}}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
        columnSearchCacheSet(searchCacheKey, columnSearch);
    })

    useEffect(() => {
        const timer = setTimeout(() => setColumnSearchParams(columnSearch), 500);

        return () => {
            clearTimeout(timer);
        };
    }, [columnSearch])

    useEffect(() => {
        setPaginationModel({page: 0, pageSize: paginationModel.pageSize})
    },[columnSearchParams])

    const columnSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newObject: {[k: string]: any} = {};

        Object.keys(columnSearch).forEach((key) => {
            newObject[key] = columnSearch[key];
        });

        newObject[event.target.id] = event.target.value;

        setColumnSearch(newObject);
    }

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        setVisibilityModel(newVisibilityModel)
    }

    return (
        <Box>
            {SwoisInfo.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!SwoisInfo.isError && (
                <Box>
                    <Box style={{height: 720, width: "100%"}}>
                        <CustomDataGrid
                            blendToTabs={blendToTabs}
                            allowSearch={false}
                            columnVisibilityModel={visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            columnConfig={columnConfig}
                            itemInfo={SwoisInfo.data}
                            allowMultiSelect
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            rowCount={rowCount.data ?? 0}
                            filterModelChanged={filterModelChanged}
                            searchButtonClick={() => setSearch(filter.current)}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={SwoisInfo.isLoading}
                            useFilterHeader={useColumnFilters}
                            columnFilter={columnSearch}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                            sortModelChanged={(model) => {
                                setSortModel(model);
                            }}
                            onColumnSearchChanged={columnSearchOnChange}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export function Swois(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="Marine sWOIS Asset Data" secondLine="Search" gridBanner/>
            <Container maxWidth="xl">
                <SwoisDataGrid id={undefined} searchCacheKey="swois" useColumnFilters/>
            </Container>
        </Box>
    );
}
