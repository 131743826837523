import axios from 'axios';

import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {GridSortModel} from "@mui/x-data-grid";
import { useAuth } from '../auth/auth.hooks';
import { AssetScanInfo } from './assetScan.types';

export const useAssetScanInfo = (
    limit: number,
    page: number,
    search: string[] | undefined,
    id: string | undefined,
    sort: GridSortModel | undefined,
    columnFilter: object | undefined
): UseQueryResult<AssetScanInfo[]> => {
    const { accessToken, config } = useAuth();

    let sortField: string | undefined;
    let sortDir: number | undefined;

    if(sort !== undefined && sort.length > 0) {
        sortField = sort[0].field;
        sortDir = sort[0].sort === "asc" ? 1 : -1;
    }

    return useQuery(
        ['asset_scan_data', { 'limit': limit, 'page': page, 'search': search, 'id': id, 'sort': sort }, columnFilter],
        () =>
            axios
                .get<AssetScanInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_scan_data`,
                    { params: {
                            limit,
                            page,
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(id !== undefined ? { id } : {}),
                            ...(sortField !== undefined ? { sort: sortField } : {}),
                            ...(sortDir !== undefined ? { sort_dir: sortDir } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
};

export const useAssetScanItem = (id: string): UseQueryResult<AssetScanInfo[]> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['asset_scan_item', { 'id': id }],
        () =>
            axios
                .get<AssetScanInfo[]>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_scan_data`,
                    { params: {
                            get_software_info: true,
                            get_patching_info: true,
                            object_id: id
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: false,
            enabled: Boolean(accessToken)
        }
    );
}

export const useAssetScanInfoCount = (
    search: string[] | undefined,
    id: string | undefined,
    columnFilter: object | undefined
): UseQueryResult<number> => {
    const { accessToken, config } = useAuth();

    return useQuery(
        ['asset_scan_data_rows', { 'search': search, 'id': id }, columnFilter],
        () =>
            axios
                .get<number>(
                    `${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_scan_data/rows`,
                    { params: {
                            ...(search !== undefined ? { search: String(search) } : {}),
                            ...(id !== undefined ? { id } : {}),
                            ...((columnFilter !== undefined && Object.keys(columnFilter).length > 0) ? {column_filter: columnFilter} : {})
                        }, headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => response.data),
        {
            staleTime: 60000,
            refetchOnWindowFocus: true,
            enabled: Boolean(accessToken),
        }
    );
}
