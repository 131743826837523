import {Alert, Box, Button, Container, SelectChangeEvent} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { GridColDef, GridColumnVisibilityModel, GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import {Banner} from "../banner/banner.component";
import {useValidationInfo, useValidationCount} from "./validation.hooks";
import {CustomDataGrid} from "../dataGrid/dataGrid.component";

import {columnSearchCacheGet, columnSearchCacheSet, searchCacheGet, searchCacheSet} from "../dataGrid/searchCache";


interface gridProps {
    searchCacheKey: string;
}

const initialVisibilityModel = {
    patch_id: false,
};


export function ValidationGrid({searchCacheKey}: Readonly<gridProps>): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [visibilityModel, setVisibilityModel] = useState<any>(initialVisibilityModel);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(undefined);
    const filter = useRef<any[] | undefined>(undefined);
    const navigate = useNavigate();
    const [columnSearch, setColumnSearch] = useState(columnSearchCacheGet(searchCacheKey));
    const [columnSearchParams, setColumnSearchParams] = useState(columnSearchCacheGet(searchCacheKey));

    const rowCount = useValidationCount(undefined, columnSearchParams);
    const validationInfo = useValidationInfo(paginationModel.pageSize, paginationModel.page, search, sortModel, columnSearchParams);

    const columnConfig: GridColDef[] = [
        // {field: "patch_id", headerName: "ID", minWidth: 50, editable: false, hideable: false, sortable: false},
        {field: "instance_name", headerName: "Name", minWidth: 140, flex: 1, editable: false},
        {field: "short_description", headerName: "Description", minWidth: 120, flex: 1, editable: false},
        {field: "related_item", headerName: "Related Item", minWidth: 140, flex: 1, editable: false},
        {field: "discovery_year", headerName: "Year", minWidth: 140, flex: 1, editable: false},
        {field: "discovery_month", headerName: "Month", minWidth: 140, flex: 1, editable: false},
        {field: "patch_status", headerName: "Status", minWidth: 140, flex: 1, editable: false},
        {field: "vendor_article_link", headerName: "Article Link", minWidth: 140, flex: 1, editable: false},
        {field: "vendor_patch_link", headerName: "Patch Link", minWidth: 140, flex: 1, editable: false},
        {field: "patch_hash", headerName: "Patch Hash", minWidth: 140, flex: 1, editable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params) => {
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const patchId = params.row.object_id;
                    navigate({pathname: `/validation/${patchId}`})
                }

                return(
                    <Button
                        style={{ float: "right" }}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
        columnSearchCacheSet(searchCacheKey, columnSearch);
    })

    useEffect(() => {
        const timer = setTimeout(() => setColumnSearchParams(columnSearch), 500);

        return () => {
            clearTimeout(timer);
        };
    }, [columnSearch])

    useEffect(() => {
        setPaginationModel({page: 0, pageSize: paginationModel.pageSize})
    },[columnSearchParams])

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        setVisibilityModel(newVisibilityModel)
    }

    const openAddPatchDialog = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        navigate({pathname: `/validation/add`})
    }

    const columnSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newObject: {[k: string]: any} = {};

        Object.keys(columnSearch).forEach((key) => {
            newObject[key] = columnSearch[key];
        });

        newObject[event.target.id] = event.target.value;

        setColumnSearch(newObject);
    }

    return(
        <Box>
            {validationInfo.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!validationInfo.isError && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box style={{height: 670, width: "100%"}}>
                        <CustomDataGrid
                            showAddNewAssetButton
                            allowSearch={false}
                            columnVisibilityModel={visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            allowMultiSelect={false}
                            columnConfig={columnConfig}
                            itemInfo={validationInfo.data}
                            rowCount={rowCount.data ?? 0}
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            filterModelChanged={filterModelChanged}
                            rowHeight={() => "auto"}
                            searchButtonClick={() => {setSearch(filter.current)}}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={validationInfo.isLoading}
                            useFilterHeader
                            columnFilter={columnSearch}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                            sortModelChanged={(model) => {
                                setSortModel(model);
                            }}
                            onAddNewAsset={openAddPatchDialog}
                            onColumnSearchChanged={columnSearchOnChange}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export function Validation(): JSX.Element {
    return (
        <Box>
            <Banner firstLine="Patch Validation" secondLine="Search" gridBanner/>
            <Container maxWidth="xl">
                <ValidationGrid searchCacheKey="validation"/>
            </Container>
        </Box>
    );
}
