import React, {FormEvent} from "react";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
import axios from "axios";
import {useAuth} from "../../auth/auth.hooks";
import {ScrInfo} from "../scr.types";
import {CancelButton, EditButton, InfoFieldPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
import {GetString} from "../../../localization";
import {Banner} from "../../banner/banner.component";
import {useScrItem} from "../scr.hooks";
import {useHistory} from "../../history/history.hooks";
import {ParseRevisionData} from "../../history/history.misc";
import {FieldHistorySet} from "../../history/history.types";

export const withHooksHOC = (Component: React.ElementType) => function useHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();
    return <Component accessToken={accessToken} config={config} queryClient={queryClient}/>;
}

interface ShowScrProps {
    objectId: string;
}

interface InfoBoxProps {
    name: string;
    data: ScrInfo;
    content: string[];
    historyChanges: FieldHistorySet[];
}

interface ViewScrProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
}

const scrInfoContents: string[] = [
    'project_name',
    'project_serial',
    'material_manufacturer_serial',
    'component_manufacturer_oem',
    'component_material_number',
    'operating_system',
    'operating_system_version',
    'product_family',
    'product_model',
]

class ViewScr extends React.Component<any, ViewScrProps> {
    static projSerial = "";

    static objectId = "";

    static FormatFieldData(data: number | string): string {
        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
        }
    }

    InfoBox = ({name, data, content, historyChanges}: InfoBoxProps): JSX.Element => {
        const {FormatFieldData} = ViewScr;
        const {updating} = this.state;
        const {editing} = this.state;

        const assetName = FormatFieldData(data[content[0]]);

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px"}}>
                <Typography style={{color: "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoFieldPair title={GetString(content[0])}
                                       name={content[0]}
                                       data={FormatFieldData(data[content[0]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[0])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[1])}
                                       name={content[1]}
                                       data={FormatFieldData(data[content[1]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[1])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[2])}
                                       name={content[2]}
                                       data={FormatFieldData(data[content[2]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[2])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[3])}
                                       name={content[3]}
                                       data={FormatFieldData(data[content[3]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[3])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[4])}
                                       name={content[4]}
                                       data={FormatFieldData(data[content[4]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[4])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[5])}
                                       name={content[5]}
                                       data={FormatFieldData(data[content[5]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[5])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[6])}
                                       name={content[6]}
                                       data={FormatFieldData(data[content[6]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[6])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                        <InfoFieldPair title={GetString(content[7])}
                                       name={content[7]}
                                       data={FormatFieldData(data[content[7]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[7])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                    </Box>
                    <Box style={{float: "right", width: "30%", flexGrow: 2}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[8])}
                                       name={content[8]}
                                       data={FormatFieldData(data[content[8]])}
                                       updating={updating}
                                       editing={editing}
                                       historyData={historyChanges.find(x => x.name === content[8])}
                                       objectId={ViewScr.objectId}
                                       source="scr_info"
                                       assetName={assetName}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    PostUpdatedData = (data: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;

        const conf = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/upload/scr_info/update`, data, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['scr_s', { 'id': ViewScr.objectId }], { exact: true })
                    queryClient.invalidateQueries(['history', { 'source': "scr_info", 'id': ViewScr.objectId}])
                }

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "success"
                })
            }).catch(() => {
            this.setState({
                editing: false,
                updating: false,
                updateStatus: "failed"
            })
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true
            });
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);

            data.append("object_id", ViewScr.objectId);

            const object = {} as ScrInfo;
            data.forEach((value, key)=> {
                object[key] = value as string;
            });

            const json = JSON.stringify(object);
            this.PostUpdatedData(json)
        }
    }

    ShowScr = ({objectId}: ShowScrProps): JSX.Element => {
        if(objectId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {dataLoaded} = this.state;

        const assetData = useScrItem(objectId);
        const revisionData = useHistory("scr_info", objectId)

        const revisionChanges = ParseRevisionData(revisionData.data);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true
            });
        }

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {assetData.isSuccess && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={assetData.data[0]} content={scrInfoContents}
                                historyChanges={revisionChanges}
                            />
                            {/* <AssetInfoBox
                            name="Installation Details"
                            data={assetData.data[0]} content={assetInfoContents}
                        /> */}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowScr} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {dataLoaded} = this.state;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const params = parts[2].split("+");

        // eslint-disable-next-line prefer-destructuring
        ViewScr.projSerial = decodeURIComponent(params[1]);
        // eslint-disable-next-line prefer-destructuring
        ViewScr.objectId = params[0];

        return (
            <Box>
                <Banner firstLine="SCR Asset Data" secondLine={`View Asset ${ViewScr.projSerial}`}/>
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            <CancelButton
                                editing={editing}
                                updating={updating}
                                onClick={() => {
                                    this.setState({
                                        editing: false
                                    })
                                }}
                            />
                            <EditButton requiredRoles={["otam_admin", "otam_scr_data"]} editing={editing} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowScr objectId={ViewScr.objectId}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewScr);
