import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Button, Collapse, Container, Divider, SelectChangeEvent, Typography} from "@mui/material";
import {GRID_CHECKBOX_SELECTION_COL_DEF, GridColumnVisibilityModel, GridFilterModel, GridRenderCellParams, GridSortModel} from "@mui/x-data-grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {CustomDataGrid} from "../../dataGrid/dataGrid.component";
import {Banner} from "../../banner/banner.component";
import {useSiteAssetCount, useSiteAssets, useSiteFacility} from "../sites.hooks";
import {columnSearchCacheGet, columnSearchCacheSet, searchCacheGet, searchCacheSet, tabCacheGet, tabCacheSet} from "../../dataGrid/searchCache";
import {GetString} from "../../../localization";
import {NetboxInfo} from "../sites.types";
import {AuthenticateRolesLocally, RoleAuthResult} from "../../auth/rolesAuth";
import {lastSeenWarningIcon} from "../../viewPageCommonElements/viewPage.component";

interface InfoFieldPairProps {
    title: string;
    data: string;
}

interface AssetInfoBoxProps {
    name: string;
    data: NetboxInfo;
    content: string[];
}

interface gridProps {
    id: string;
    type: string | undefined;
    searchCacheKey: string;
    gridType: TabType;
    useColumnFilters?: boolean;
}

interface ShowTabProps {
    tabConfig: tabSettings[];
    tabVisibleIndex: number;
    id: string;
}

enum TabType {
    NA = -1,
    Firewalls = 0,
    NetworkSwitches = 1,
    OTAssets = 2,
    PCs = 3,
}

type tabSettings = {
    name: string; // Name of the tab
    iconPath: string | undefined; // Path to tab icon
    itemCount: number; // The amount of items this asset type has (if any), used to determine if tab should be shown
    tabType: TabType; // Which type of tab is this?
    visibleIndex: number; // Index of tab relative to the visible ones (-1 = not visible)
    [key: string]: (number | string | string[] | undefined);
}

interface TabButtonProps {
    name: string;
    icon: string | undefined;
    thisTab: number;
    currentTab: number;
    onButtonClick: MouseEventHandler<HTMLButtonElement>;
}

const siteDetailsContent: string[] = [
    'name',
    'group',
    'physical_address',
    'description',
    'time_zone',
    'status',
    'region',
    'comments',
]

const initialVisibilityModel = {
    object_id: false,
    created_by: false,
    created_at: false,
};

const initialVisibilityModelPc = {
    object_id: false,
    firmware_version: false,
    serial_number: false,
    ip_cidr: false,
    user: false,
    created_by: false,
    last_updated_by: false,
    mac_vendor: false,
    created_at: false,
};

let site = "-1";

function InfoFieldPair({title, data}: InfoFieldPairProps): JSX.Element {
    const text = <Typography style={{fontSize: "medium", whiteSpace: "normal", wordWrap: "break-word"}} sx={{marginTop: 0, marginBottom: 1.4, marginLeft: 0, marginRight: 0, padding: 0}}>
        {data === null ? ("-") : (data)}
    </Typography>

    return <Box>
        <Typography style={{fontSize: "medium"}} sx={{margin: 0, padding: 0}}>
            <strong>{title}</strong>
        </Typography>
        {text}
        <br/>
    </Box>
}

function FormatFieldData(data: number | string | string[]): string {
    if(Array.isArray(data)) {
        const list = data.toString();
        return list.split(",").join("\n");
    }

    return data as string;
}

function AssetInfoBox({name, data, content}: AssetInfoBoxProps): JSX.Element {
    const [collapse, setCollapse] = useState(false);

    return (
        <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px", cursor: "pointer"}}>
            <Box sx={{display: "flex"}} onClick={() => {
                setCollapse(!collapse);
            }}>
                <Typography style={{color: collapse ? "#ff7321" : "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Box sx={{ml: "10px"}}>
                    {collapse ? <KeyboardArrowDownIcon sx={{pt: "3px"}}/> : <KeyboardArrowUpIcon sx={{pt: "3px"}}/>}
                </Box>
            </Box>
            <Collapse in={!collapse} sx={{cursor: "auto"}}>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "25%"}}>
                        <InfoFieldPair title={GetString(content[0])} data={FormatFieldData(data[content[0]])}/>
                        <InfoFieldPair title={GetString(content[1])} data={FormatFieldData(data[content[1]])}/>
                    </Box>
                    <Box style={{float: "left", width: "25%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[2])} data={FormatFieldData(data[content[2]])}/>
                        <InfoFieldPair title={GetString(content[3])} data={FormatFieldData(data[content[3]])}/>
                    </Box>
                    <Box style={{float: "right", width: "25%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[4])} data={FormatFieldData(data[content[4]])}/>
                        <InfoFieldPair title={GetString(content[5])} data={FormatFieldData(data[content[5]])}/>
                    </Box>
                    <Box style={{float: "right", width: "25%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[6])} data={FormatFieldData(data[content[6]])}/>
                        <InfoFieldPair title={GetString(content[7])} data={FormatFieldData(data[content[7]])}/>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}

function TabButton({name, icon, thisTab, currentTab, onButtonClick}: TabButtonProps): JSX.Element {
    return(
        <Button
            disableRipple
            style={{
                color: "#ff7321",
                backgroundColor: "#0f172b",
                borderRadius: 0,
                marginTop: -3,
                marginLeft: 10,
                marginRight: 10,
                marginBottom: -15,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 3,
                paddingBottom: currentTab === thisTab ? 9 : 13,
                minWidth: 0,
                fontSize: 12,
                fontWeight: currentTab === thisTab ? "500" : "400",
                textTransform: "none",
                borderBottom: currentTab === thisTab ? "5px solid" : "0px",
                verticalAlign: "middle"
            }}
            onClick={onButtonClick}>
            <span style={{color: "#ffffff"}}>
                <Box style={{display: "flex"}}>
                    <Box style={{ float: "left", marginRight: "4px", marginTop: "-2px", marginBottom: "-9px" }}>
                        {icon !== undefined && <img src={icon} alt={`${name}-icon`} width="30px" height="30px"/>}
                    </Box>
                    <Box style={{ clear: "both" }}>
                        {name}
                    </Box>
                </Box>
            </span>
        </Button>
    );
}

function SiteAssetsGrid({id, type, searchCacheKey, gridType, useColumnFilters}: gridProps): JSX.Element {
    const [paginationModel, setPaginationModel] = useState({page: 0, pageSize: 10});
    const [search, setSearch] = useState<any[] | undefined>(searchCacheGet(searchCacheKey));
    const [visibilityModel, setVisibilityModel] = useState<any>(initialVisibilityModel);
    const [visibilityModelPc, setVisibilityModelPc] = useState<any>(initialVisibilityModelPc);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(undefined);
    const [columnSearch, setColumnSearch] = useState(columnSearchCacheGet(searchCacheKey));
    const [columnSearchParams, setColumnSearchParams] = useState(columnSearchCacheGet(searchCacheKey));

    const filter = useRef<any[] | undefined>(undefined);

    const siteAssets = useSiteAssets(Number(id), paginationModel.page, paginationModel.pageSize, type, search, sortModel, columnSearchParams);
    const rowCount = useSiteAssetCount(type, search, id, columnSearchParams);

    const defaultColumnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false},
        {field: "hostname", headerName: "Device Name", minWidth: 100, flex: 1, editable: false},
        {field: "vendor", headerName: "Vendor", minWidth: 160, flex: 1, editable: false},
        {field: "model", headerName: "Model", minWidth: 120, flex: 1, editable: false},
        {field: "firmware_version", headerName: "Firmware Version", minWidth: 140, flex: 1, editable: false},
        {field: "mac_address", headerName: "MAC Address", minWidth: 100, flex: 1, editable: false},
        {field: "serial_number", headerName: "Serial Number", minWidth: 120, flex: 1, editable: false},
        {field: "vlan", headerName: "VLAN", minWidth: 140, flex: 1, editable: false},
        {field: "created_by", headerName: "Created By", minWidth: 120, flex: 1, editable: false},
        {field: "created_at", headerName: "Created At", minWidth: 145, flex: 1, editable: false, renderCell: (params: GridRenderCellParams) => {
                const text = params.value !== undefined ? new Date(params.value).toDateString() : "";
                return(
                    <Typography>{text}</Typography>
                );
            }
        },
        {field: "last_seen", headerName: "Last Seen", minWidth: 160, flex: 1, editable: false, renderCell: (params: GridRenderCellParams) => {
                let text = "";
                let tooOld = false;

                if(params.value !== undefined) {
                    const lastSeen = new Date(params.value);
                    const lastValidDate = new Date();
                    lastValidDate.setMonth(lastValidDate.getMonth() - 3);
                    tooOld = lastSeen < lastValidDate;

                    text = lastSeen.toDateString();
                }

                return(
                    <Box>
                        <Box style={{float: "right", minWidth: "40px"}}>
                            {tooOld && lastSeenWarningIcon}
                        </Box>
                        <Box style={{width: "auto", overflow: "hidden"}}>
                            <Typography>
                                {text}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        },
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const navigate = useNavigate();
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.object_id;
                    // eslint-disable-next-line prefer-destructuring
                    const hostname = params.row.hostname;
                    navigate({pathname: `/internal_sites/${site}/view/${objectId}+${hostname}`})
                }

                return(
                    <Button
                        style={{ float: "right" }}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    const columnConfigAsset = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false},
        {field: "hostname", headerName: "Device Name", minWidth: 100, flex: 1, editable: false},
        {field: "vendor", headerName: "Vendor", minWidth: 160, flex: 1, editable: false},
        {field: "model", headerName: "Model", minWidth: 120, flex: 1, editable: false},
        {field: "type", headerName: "Type", minWidth: 110, flex: 1, editable: false},
        {field: "firmware_version", headerName: "Firmware Version", minWidth: 140, flex: 1, editable: false},
        {field: "serial_number", headerName: "Serial Number", minWidth: 120, flex: 1, editable: false},
        {field: "ip_address", headerName: "IP Address", minWidth: 140, flex: 1, editable: false},
        {field: "mac_address", headerName: "MAC Address", minWidth: 100, flex: 1, editable: false},
        {field: "vlan", headerName: "VLAN", minWidth: 140, flex: 1, editable: false},
        {field: "created_by", headerName: "Created By", minWidth: 120, flex: 1, editable: false},
        {field: "created_at", headerName: "Created At", minWidth: 145, flex: 1, editable: false, renderCell: (params: GridRenderCellParams) => {
                const text = params.value !== undefined ? new Date(params.value).toDateString() : "";
                return(
                    <Typography>{text}</Typography>
                );
            }
        },
        {field: "last_seen", headerName: "Last Seen", minWidth: 160, flex: 1, editable: false, renderCell: (params: GridRenderCellParams) => {
                let text = "";
                let tooOld = false;

                if(params.value !== undefined) {
                    const lastSeen = new Date(params.value);
                    const lastValidDate = new Date();
                    lastValidDate.setMonth(lastValidDate.getMonth() - 3);
                    tooOld = lastSeen < lastValidDate;

                    text = lastSeen.toDateString();
                }

                return(
                    <Box>
                        <Box style={{float: "right", minWidth: "40px"}}>
                            {tooOld && lastSeenWarningIcon}
                        </Box>
                        <Box style={{width: "auto", overflow: "hidden"}}>
                            <Typography>
                                {text}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        },
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const navigate = useNavigate();
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.object_id;
                    // eslint-disable-next-line prefer-destructuring
                    const hostname = params.row.hostname;
                    navigate({pathname: `/internal_sites/${site}/view/${objectId}+${hostname}`})
                }

                return(
                    <Button
                        style={{ float: "right" }}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    const pcColumnConfig = [
        {...GRID_CHECKBOX_SELECTION_COL_DEF, width: 60, hideable: false},
        {field: "object_id", headerName: "ID", minWidth: 50, editable: false, hideable: false},
        {field: "hostname", headerName: "Device Name", minWidth: 150, flex: 1, editable: false},
        {field: "vendor", headerName: "Vendor", minWidth: 120, flex: 1, editable: false},
        {field: "model", headerName: "Model", minWidth: 120, flex: 1, editable: false},
        {field: "os", headerName: "OS", minWidth: 110, flex: 1, editable: false},
        {field: "os_version", headerName: "OS Version", minWidth: 140, flex: 1, editable: false},
        {field: "mac_vendor", headerName: "MAC Vendor", minWidth: 140, flex: 1, editable: false},
        {field: "mac_address", headerName: "MAC Address", minWidth: 150, flex: 1, editable: false},
        {field: "ip_address", headerName: "IP Address", minWidth: 140, flex: 1, editable: false},
        {field: "ip_cidr", headerName: "IP CIDR", minWidth: 140, flex: 1, editable: false},
        {field: "vlan", headerName: "VLAN", minWidth: 90, flex: 1, editable: false},
        {field: "serial_number", headerName: "Serial Number", minWidth: 120, flex: 1, editable: false},
        {field: "firmware_version", headerName: "Firmware Version", minWidth: 120, flex: 1, editable: false},
        {field: "user", headerName: "User", minWidth: 120, flex: 1, editable: false},
        {field: "created_by", headerName: "Created By", minWidth: 120, flex: 1, editable: false},
        {field: "created_at", headerName: "Created At", minWidth: 145, flex: 1, editable: false, renderCell: (params: GridRenderCellParams) => {
                const text = params.value !== undefined ? new Date(params.value).toDateString() : "";
                return(
                    <Typography>{text}</Typography>
                );
            }
        },
        {field: "last_seen", headerName: "Last Seen", minWidth: 160, flex: 1, editable: false, renderCell: (params: GridRenderCellParams) => {
                let text = "";
                let tooOld = false;

                if(params.value !== undefined) {
                    const lastSeen = new Date(params.value);
                    const lastValidDate = new Date();
                    lastValidDate.setMonth(lastValidDate.getMonth() - 3);
                    tooOld = lastSeen < lastValidDate;

                    text = lastSeen.toDateString();
                }

                return(
                    <Box>
                        <Box style={{float: "right", minWidth: "40px"}}>
                            {tooOld && lastSeenWarningIcon}
                        </Box>
                        <Box style={{width: "auto", overflow: "hidden"}}>
                            <Typography>
                                {text}
                            </Typography>
                        </Box>
                    </Box>
                );
            }
        },
        {field: "last_updated_by", headerName: "Last Updated By", minWidth: 120, flex: 1, editable: false},
        {field: "actions", headerName: "Actions", minWidth: 95, flex: 0, editable: false, hideable: false, sortable: false, renderCell: (params: GridRenderCellParams) => {
                const navigate = useNavigate();
                const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                    event.stopPropagation();

                    const objectId = params.row.object_id;
                    // eslint-disable-next-line prefer-destructuring
                    const hostname = params.row.hostname;
                    navigate({pathname: `/internal_sites/${site}/view/${objectId}+${hostname}`})
                }

                return(
                    <Button
                        style={{ float: "right" }}
                        variant="text"
                        onClick={onClick}
                        disableRipple>
                        View
                    </Button>
                );
            }
        }
    ]

    useEffect(() => {
        setColumnSearch(columnSearchCacheGet(searchCacheKey))
    }, [type])

    useEffect(() => {
        searchCacheSet(searchCacheKey, search);
        columnSearchCacheSet(searchCacheKey, columnSearch);
    })

    useEffect(() => {
        const timer = setTimeout(() => setColumnSearchParams(columnSearch), 500);

        return () => {
            clearTimeout(timer);
        };
    }, [columnSearch])

    useEffect(() => {
        setPaginationModel({page: 0, pageSize: paginationModel.pageSize})
    },[columnSearchParams])

    const columnSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newObject: {[k: string]: any} = {};

        Object.keys(columnSearch).forEach((key) => {
            newObject[key] = columnSearch[key];
        });

        newObject[event.target.id] = event.target.value;

        setColumnSearch(newObject);
    }

    const paginationModelChanged = (newPaginationModel: {page: number, pageSize: number}): void => {
        setPaginationModel(newPaginationModel);
    };

    const filterModelChanged = (newModel: GridFilterModel): void => {
        filter.current = newModel.quickFilterValues;
        if(filter.current === undefined || filter.current?.length === 0) {
            // If search bar was cleared, return to the unfiltered data
            setSearch(filter.current);
        }
    };

    const visibilityModelChanged = (newVisibilityModel: GridColumnVisibilityModel): void => {
        if(gridType === TabType.PCs) {
            setVisibilityModelPc(newVisibilityModel)
        }
        else {
            setVisibilityModel(newVisibilityModel)
        }

    }

    let columns;

    switch(gridType) {
        case TabType.OTAssets:
            columns = columnConfigAsset;
            break;

        case TabType.PCs:
            columns = pcColumnConfig;
            break;

        case TabType.NA:
        case TabType.Firewalls:
        case TabType.NetworkSwitches:
        default:
            columns = defaultColumnConfig;
            break;
    }

    return(
        <Box>
            {siteAssets.isError && (
                <Alert severity="error" sx={{ mb: 2 }}>Something went wrong while loading backend status</Alert>
            )}
            {!siteAssets.isError && (
                <Box>
                    <Box style={{height: 670, width: "100%"}}>
                        <CustomDataGrid
                            blendToTabs
                            allowSearch={false}
                            columnVisibilityModel={gridType === TabType.PCs ? visibilityModelPc : visibilityModel}
                            columnVisibilityModelChanged={visibilityModelChanged}
                            columnConfig={columns}
                            itemInfo={siteAssets.data}
                            allowMultiSelect
                            paginationModel={paginationModel}
                            paginationModelChanged={paginationModelChanged}
                            rowCount={rowCount.data ?? 0}
                            filterModelChanged={filterModelChanged}
                            searchButtonClick={() => {setSearch(filter.current)}}
                            searchEnterKey={() => {setSearch(filter.current)}}
                            initSearch={search}
                            isLoading={siteAssets.isLoading}
                            useFilterHeader={useColumnFilters}
                            columnFilter={columnSearch}
                            onPageChanged={(event, page) =>
                                setPaginationModel({
                                    page: page - 1,
                                    pageSize: paginationModel.pageSize
                                })
                            }
                            onPageSizeChanged={(event: SelectChangeEvent<number>) => {
                                setPaginationModel({
                                    page: paginationModel.page,
                                    pageSize: event.target.value as number
                                })
                            }}
                            sortModelChanged={(model) => {
                                setSortModel(model);
                            }}
                            onColumnSearchChanged={columnSearchOnChange}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function ShowTab({tabConfig, tabVisibleIndex, id}: ShowTabProps): JSX.Element {
    let index: TabType = TabType.NA;

    // Find the tab that is selected and get it's absolute index to get the right component
    for(let i = 0; i < tabConfig.length; i += 1) {
        if(tabConfig[i].itemCount !== 0 && tabConfig[i].visibleIndex === tabVisibleIndex) index = tabConfig[i].tabType;
    }

    // Get the tab component that matches the tab index
    switch (index) {
        case TabType.Firewalls:
            return <SiteAssetsGrid type="Firewall" id={id} searchCacheKey="internal-site-firewall" gridType={TabType.Firewalls} useColumnFilters/>
        case TabType.NetworkSwitches:
            return <SiteAssetsGrid type="Switch" id={id} searchCacheKey="internal-site-switch" gridType={TabType.NetworkSwitches} useColumnFilters/>
        case TabType.PCs:
            return <SiteAssetsGrid type="computer" id={id} searchCacheKey="internal-site-pc" gridType={TabType.PCs} useColumnFilters/>
        case TabType.OTAssets:
            return <SiteAssetsGrid type="" id={id} searchCacheKey="internal-site-ot-assets" gridType={TabType.OTAssets} useColumnFilters/>
        case TabType.NA:
        default:
            return <Alert severity="error" sx={{ mb: 2 }}>Something went wrong opening this tab</Alert>
    }
}

function clickFunction(tabIndex: number, setTab: React.Dispatch<React.SetStateAction<number>>):  React.MouseEventHandler<HTMLButtonElement> {
    return () => setTab(tabIndex);
}

function AssetTabs(tabConfig: tabSettings[], currentTab: number, setTab: React.Dispatch<React.SetStateAction<number>>, tabCount: number, blendToGrid = false): JSX.Element {
    // Return info box if no assets were found
    if(tabCount === 0) {
        return <Alert severity="info">No asset data found</Alert>
    }

    const config = tabConfig; // Workaround for "Must use destructuring tabConfig assignment" error
    const tabs = []
    let tabIndex = 0;

    // Create array of React components for the tab buttons
    for(let i = 0; i < config.length; i += 1) {
        if(config[i].itemCount > 0) {
            tabs.push(
                <React.Fragment key={i}>
                    <TabButton name={config[i].name} icon={config[i].iconPath} thisTab={tabIndex} currentTab={currentTab} onButtonClick={clickFunction(tabIndex, setTab)}/>
                </React.Fragment>
            )
            tabIndex += 1;
        }
    }

    // Return tab buttons
    return(
        <Box
            className="tabBar"
            style={{
                marginBottom: blendToGrid ? 0 : "7px",
                borderBottomLeftRadius : blendToGrid ? 0 : "5px",
                borderBottomRightRadius : blendToGrid ? 0 : "5px",
                minWidth: "820px",
            }}>
            {tabs}
        </Box>
    );
}

export function ViewInternalSite(): JSX.Element {
    const {pathname} = window.location;
    const parts = pathname.split("/");
    site = parts[2] as string;

    const navigate = useNavigate();

    const initialTab = tabCacheGet("internal-site")
    const [tab, setTab] = useState(initialTab === undefined ? 0 : initialTab);
    const [countsLoading, setCountsLoading] = useState(true);

    const siteDetails = useSiteFacility(Number(site));

    const firewallCountResult = useSiteAssetCount("Firewall", undefined, undefined, undefined);
    const switchCountResult = useSiteAssetCount("Switch", undefined, undefined, undefined);
    const otAssetCountResult = useSiteAssetCount("", undefined, undefined, undefined);
    const otPCCountResult = useSiteAssetCount("computer", undefined, undefined, undefined);

    const firewallCount = firewallCountResult.data;
    const switchCount = switchCountResult.data;
    const otAssetCount = otAssetCountResult.data;
    const otPCCount = otPCCountResult.data;

    useEffect(() => {
        tabCacheSet("internal-site", tab);
    })

    useEffect(() => {
        if(!firewallCountResult.isLoading && !switchCountResult.isLoading &&
            !otAssetCountResult.isLoading && !otAssetCountResult.isLoading) {
            setCountsLoading(false);
        }
    }, [
        firewallCountResult.isLoading,
        switchCountResult.isLoading,
        otAssetCountResult.isLoading,
        otAssetCountResult.isLoading
    ])

    const tabConfig: tabSettings[] = [
        {
            name: "Firewalls",
            iconPath: "/img/firewall.svg",
            itemCount: 0,
            tabType: TabType.Firewalls,
            visibleIndex: -1,
        },
        {
            name: "Network Switches",
            iconPath: "/img/switch.svg",
            itemCount: 0,
            tabType: TabType.NetworkSwitches,
            visibleIndex: -1,
        },
        {
            name: "OT Assets",
            iconPath: "/img/ot_asset.svg",
            itemCount: 0,
            tabType: TabType.OTAssets,
            visibleIndex: -1,
        },
        {
            name: "OT PCs",
            iconPath: "/img/laptop.svg",
            itemCount: 0,
            tabType: TabType.PCs,
            visibleIndex: -1,
        }
    ]

    tabConfig[0].itemCount = firewallCount === undefined ? 0 : firewallCount;
    tabConfig[1].itemCount = switchCount === undefined ? 0 : switchCount;
    tabConfig[2].itemCount = otAssetCount === undefined ? 0 : otAssetCount;
    tabConfig[3].itemCount = otPCCount === undefined ? 0 : otPCCount;

    let tabCount = 0;

    // Count how many tabs have assets and user has access to and assign visible indices
    for(let i = 0; i < tabConfig.length; i += 1) {
        if(tabConfig[i].itemCount > 0) {
            tabConfig[i].visibleIndex = tabCount;
            tabCount += 1;
        }
    }

    const openAddAssetDialog = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        navigate({pathname: `/internal_sites/${site}/view/add`})
    }

    return(
        <Box>
            <Banner firstLine={`Internal Site ${site}`} secondLine="View"/>
            <Container maxWidth="xl">
                <Box style={{minWidth: "864px"}}>
                    <Box style={{ float: "right" }}>
                        {AuthenticateRolesLocally(["otam_admin", "otam_internal"]) === RoleAuthResult.RoleFound && <Button
                            variant="text"
                            onClick={openAddAssetDialog}
                            style={{
                                margin: 8,
                                color: "#ffffff",
                                backgroundColor: "#0f334a" }}
                            disableRipple>
                            Add
                        </Button>}
                    </Box>
                </Box>
                <Box style={{clear: "both"}}>
                    {(siteDetails.isLoading || countsLoading) && <Alert severity="info" sx={{ mb: 2, mt:2 }}>Loading...</Alert>}
                    {siteDetails.isError && (
                        <Alert severity="error" sx={{ mb: 2 }}>Installation data could not be loaded</Alert>
                    )}
                    {siteDetails.isSuccess && (
                        <Box>
                            <Box className="viewAssetContainer">
                                <AssetInfoBox name="Installation" content={siteDetailsContent} data={siteDetails.data.sites[0]}/>
                            </Box>

                        </Box>
                    )}
                    {!countsLoading && <Box sx={{mt: 2}}>
                        <Typography style={{color: "#ff7321", fontWeight: "bold", fontSize: "larger", paddingLeft: 8}}>
                            Asset Data
                        </Typography>
                        {AssetTabs(tabConfig, tab, setTab, tabCount, true)}
                        {tabCount > 0 && <ShowTab tabConfig={tabConfig} tabVisibleIndex={tab} id={site}/>}
                    </Box>}
                </Box>
            </Container>
        </Box>
    );
}
