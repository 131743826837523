import React, { FormEvent } from "react";
import axios from "axios";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
import {Navigate} from "react-router-dom";
import {useAuth} from "../../auth/auth.hooks";
import {PatchValidation} from "../validation.types";
import {CancelButton, EditButton, InfoDatePickerPair, InfoDropdownPair, InfoFieldPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
import {GetString} from "../../../localization";
import {Banner} from "../../banner/banner.component";
import {useValidationItem} from "../validation.hooks";


type UrlParams = {
    object_id: string;
}

export const withHooksHOC = (Component: React.ElementType) => function useHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();

    const {pathname} = window.location;
    const parts = pathname.split("/");
    const groupParams = parts[2].split("+")

    const params: UrlParams = {
        object_id: groupParams[0],
    }

    return <Component accessToken={accessToken} config={config} queryClient={queryClient} params={params}/>;
}

interface ShowSesProps {
    objectId: string;
}

interface InfoBoxProps {
    objectId: string;
    name: string;
    data: PatchValidation;
    content: string[];
}

interface ViewValidationProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
    defaultData?: PatchValidation;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
    params: UrlParams;
}

const patchInfoContents: string[] = [
    'instance_name',
    'short_description',
    'related_item',
    'discovery_year',
    'discovery_month',
    'patch_status',
    'vendor_article_link',
    'vendor_patch_link',
    'patch_hash',
]

const RelatedItems = [
    "ClamAV",
    "Microsoft Defender",
    "Ubuntu 20.04",
    "Ubuntu 22.04",
    "Windows Server 2012 R2",
    "Windows Server 2016 SP0",
    "Windows Server 2022 SP0",
]

const PatchStatus = [
    "Approved",
    "Rejected",
    "Superseded",
]

class ViewPatch extends React.Component<any, ViewValidationProps> {
    static addReturn = {
        "object_id": "",
    };

    static FormatFieldData(data: number | string | undefined): string {
        if (data === undefined) {
            return "";
        }
        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
        }
        this.InfoBox = this.InfoBox.bind(this);
        this.ShowPatch = this.ShowPatch.bind(this);
        this.EditFormSubmit = this.EditFormSubmit.bind(this);
        this.PostData = this.PostData.bind(this);
    }

    componentDidMount(): void {
        const {params} = this.props;

        if (params.object_id === "add") {
            this.setState({
                editing: true
            })
        }
    }

    PostData = (data: PatchValidation, postType: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;
        const {params} = this.props;

        this.setState({
            updateStatus: params.object_id === "add" ? "adding" : "updating",
            defaultData: data
        })

        const conf = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/validation/${postType}`, {
            ...(params.object_id === "add" ? {} : {object_id: params.object_id}),
            instance_name: data.instance_name,
            short_description: data.short_description,
            related_item: data.related_item,
            discovery_year: data.discovery_year,
            discovery_month: data.discovery_month,
            patch_status: data.patch_status,
            vendor_article_link: data.vendor_article_link,
            vendor_patch_link: data.vendor_patch_link,
            patch_hash: data.patch_hash,
        }, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['validation', { 'id': params.object_id }], { exact: true })
                }

                ViewPatch.addReturn.object_id = response.data.object_id;

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: params.object_id === "add" ? "added" : "success"
                })
            }).catch(error => {
            if(!error.response) {
                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "failed"
                })
            }
            else {
                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: "failed"
                })
            }
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;
        const {params} = this.props;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true
            });
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: params.object_id === "add" ? "adding" : "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);
            data.set("object_id", params.object_id);

            const object = {} as PatchValidation;

            data.forEach((value, key)=> {
                if(key.startsWith("discovery")) {
                    const date = new Date(value as string);
                    // getMonth() returns the month index (0-11) in the date so we need to increment it by 1 to get actual month number
                    object.discovery_month = date.getMonth() + 1;
                    object.discovery_year = date.getFullYear();
                }
                else {
                    object[key] = value as string;
                }
            });

            if (params.object_id === "add") {
                this.PostData(object, "add")
            }
            else {
                this.PostData(object, "update")
            }
        }
    }

    InfoBox = ({objectId, name, data, content}: InfoBoxProps): JSX.Element => {
        const {FormatFieldData} = ViewPatch;
        const {updating} = this.state;
        const {editing} = this.state;
        const discoveryDate = objectId === "add" ? new Date() : new Date(`${FormatFieldData(data[content[3]])}-${FormatFieldData(data[content[4]])}`); 
        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%", minWidth: "820px"}}>
                <Typography style={{color: "#000000", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Divider sx={{mt: 0.5, mb: 3, borderColor: "#ff7321", borderBottomWidth: 2}}/>
                <Box style={{display: "flex"}}>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoFieldPair title={GetString(content[0])} name={content[0]} data={FormatFieldData(data[content[0]])} updating={updating} editing={editing}/>
                        <InfoFieldPair title={GetString(content[1])} name={content[1]} data={FormatFieldData(data[content[1]])} updating={updating} editing={editing}/>
                        <InfoDropdownPair title={GetString(content[2])} name={content[2]} data={FormatFieldData(data[content[2]])} dropdownOptions={RelatedItems} updating={updating} editing={editing}/>

                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                    <InfoDatePickerPair title="Discovered" name="discovery" data={discoveryDate} views={['month', 'year']} updating={updating} editing={editing}/>
                        <InfoDropdownPair title={GetString(content[5])} name={content[5]} data={FormatFieldData(data[content[5]])} dropdownOptions={PatchStatus} updating={updating} editing={editing}/>
                    </Box>
                    <Box style={{float: "right", width: "30%", flexGrow: 2}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[6])} name={content[6]} data={FormatFieldData(data[content[6]])} updating={updating} editing={editing}/>
                        <InfoFieldPair title={GetString(content[7])} name={content[7]} data={FormatFieldData(data[content[7]])} updating={updating} editing={editing}/>
                        <InfoFieldPair title={GetString(content[8])} name={content[8]} data={FormatFieldData(data[content[8]])} updating={updating} editing={editing}/>
                    </Box>
                </Box>
            </Box>
        );
    }

    ShowPatch = ({objectId}: ShowSesProps): JSX.Element => {
        if(objectId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {dataLoaded} = this.state;
        const {defaultData} = this.state;

        if(objectId === "add") {
            const newPatch: PatchValidation = {
                instance_name: "",
                short_description: "",
                related_item: "",
                patch_status: "",
                vendor_article_link: "",
                vendor_patch_link: "",
                patch_hash: "",
                object_id: "-1",
            };

            if(!dataLoaded) {
                this.setState({
                    dataLoaded: true,
                    defaultData: newPatch,
                });
            }

            return (
                <Box>
                    <Box className="viewAssetContainer">
                        <InfoBox
                            objectId={objectId}
                            name="Asset Information"
                            data={newPatch} content={patchInfoContents}
                        />
                    </Box>
                </Box>
            );
        }
        const assetData = useValidationItem(objectId);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true,
                defaultData: assetData.data
            });
        }

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {defaultData && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                objectId={objectId}
                                name="Asset Information"
                                data={defaultData} content={patchInfoContents}
                            />
                            {/* <AssetInfoBox
                            name="Installation Details"
                            data={assetData.data[0]} content={assetInfoContents}
                        /> */}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowPatch} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {dataLoaded} = this.state;
        const {params} = this.props;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const groupParams = parts[2].split("+")

        // eslint-disable-next-line prefer-destructuring
        params.object_id = groupParams[0];

        const bannerText = params.object_id === "add" ? "Add new patch" : `View Asset ID ${params.object_id}`;

        return (
            <Box>
                {params.object_id === "add" && updateStatus === "added" &&
                    <Navigate
                        to={`/validation/${ViewPatch.addReturn.object_id}`}
                        replace
                    />
                }
                <Banner firstLine="Patch Data" secondLine={bannerText}/>
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            {params.object_id !== "add" && (
                                <CancelButton
                                    editing={editing}
                                    updating={updating}
                                    onClick={() => {
                                        this.setState({
                                            editing: false
                                        })
                                    }}
                                />
                            )}
                            <EditButton requiredRoles={["otam_admin", "otam_patch_validation"]} editing={editing} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowPatch objectId={params.object_id}/>
                        </Box>
                    </form>
                </Container>
            </Box>
        );
    }
}

export default withHooksHOC(ViewPatch);
